.plan-service-points {
  color: $ps-font-black;
  padding: 35px 0 0;
  margin-bottom: 58px;

  &_headline {
    text-align: center;
    margin-bottom: 18px;
  }

  &_text {
    text-align: center;
    margin-bottom: 18px;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;

    &_item {
      flex: 0 0 50%;
      max-width: 50%;

      &_content {
        padding: 42px 21px 24px;
        margin: 3px;
        text-align: center;
        background-color: $ps-bg-white;
      }

      &_icon {
        width: 44px;
        height: 44px;
        margin-bottom: 20px;
        object-fit: contain;
      }

      &_headline {
        font-size: 15px;
        line-height: 1.2;
        display: block;
      }

      &_text {
        font-size: 11px;
        line-height: 1.2;
        margin-bottom: 0;
      }
    }
  }
}


@media only screen and (min-width: $tablet) {
  .plan-service-points {
    background-color: $ps-bg-white;

    &_list {
      margin: 0;

      &_item {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;

        &_icon {
          width: 85px;
          height: 85px;
          margin-bottom: 40px;
        }

        &_content {
          padding: 55px 21px 65px;
          margin: 0;
          background-color: transparent;
        }

        &_headline {
          font-size: 30px;
          display: block;
        }

        &_text {
          font-size: 22px;
        }
      }
    }
  }
}
