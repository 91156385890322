.my-account {
  color: $ps-font-black;

  .sidenav_container {
    top: 100px;
  }

  &_section {
    background-color: $ps-bg-white;
  }

  &_content {
    flex: 1 1 auto;
    width: 100%;
  }

  &_sidenav {
    display: none;
  }

  &_contract_table_key {
    text-transform: uppercase;
  }

  .contract-data-overview {
    //display: block;
    //width: 50%;
    //background-color: red;
  }
}

.reports_body {
  tbody tr:last-child {
    background-color: white;
  }

  .show_all {
    button {
      margin: 0 auto;
      padding: 3px 10px;
      width: 20%;
      min-width: 150px;
      background-color: white;
      border: 1px solid black;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .my-account {
    &_sidenav {
      display: block;
    }
  }
}

// Invoice History
#invoice_history {
  .hideItems, .disable-button {
    display: none;
  }
}

// My Account Cancel Protection Plan
.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}
