.newsletter {
  background-color: $ps-bg-blue;

  padding: 20px 13px;

  &_wrapper {
    text-align: center;
  }

  &_headline {
    color: $ps-font-white;
    margin-bottom: 20px;
  }

  &_right {
    position: relative;
    margin: 20px 20px 0;

    input {
      z-index: 5;
      height: 50px;
      width: 100%;
      border-radius: 5px;
      padding-left: 20px;
      padding-right: 180px;
      @include placeholder {
        margin-left: 20px;
      }
    }

    a {
      z-index: 10;
      position: absolute;
      right: 10px;
      top: 7px;
      background-color: $ps-bg-blue;
      border-radius: 5px;
      padding: 5px 30px;
      color: $ps-font-white;
      border: none;
    }

    button{
      z-index: 10;
      position: absolute;
      right: 10px;
      top: 9px;
      background-color: $ps-bg-blue;
      border-radius: 5px;
      padding: 10px 30px;
      color: $ps-font-white;
      border: none;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .newsletter {
    padding: 80px 0;

    &_wrapper {
      display: flex;
      text-align: left;
    }

    &_headline {
      margin-bottom: 50px;
    }

    &_left, &_right {
      flex: 0 0 50%
    }

    &_right {
      position: relative;
      margin: 50px 0px 0;

      input {
        z-index: 5;
        position: absolute;
        right: 0;
        height: 50px;
        max-width: 490px;
        border-radius: 5px;
        width: 100%;
        padding-left: 20px;
        @include placeholder {
          margin-left: 20px;
        }
      }

      button {
        z-index: 10;
        position: absolute;
        right: 10px;
        top: 9px;
        background-color: $ps-bg-blue;
        border-radius: 5px;
        padding: 10px 30px;
        color: $ps-font-white;
        border: none;
      }
    }
  }
}

