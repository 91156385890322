.planCard {
  color: $ps-font-black;

  &_headline {
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }
  }

  &_list {
    list-style: none;
    padding-left: 0;

    &_desktop {
      display: none;
    }

    &_box {
      position: relative;
      padding-bottom: 10px;
    }

    &_headline {
      width: 200px;
      position: relative;
      left: 50px;
    }

    &_iconList {
      height: 100px;
      width: auto;
      margin-bottom: 30px;
    }

    &_image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 215px;
      margin-left: 30px;
      margin-top: 10px;


      &_plusicon {
        background-image: url("/static/image/icons/plus/black_plus.png");
        background-size: 48px;
        height: 48px;
        width: 48px;
        position: absolute;
        top: 18px;
        left: 5px;
      }
    }

    &_iconlist {
      margin-top: 75px;
      margin-left: 210px;

      &_item {
        width: 50%;
        float: right;

        .icon {

        }

        .text {

        }
      }
    }

    &_link {
      text-decoration: underline;
    }

    &_btns {
      margin-top: 70px;
    }

    &_subheadline {
      margin-bottom: 40px;
    }

    &_text {
      margin-bottom: 30px;
    }

    &_info {
      font-weight: bold;
      margin-bottom: 30px;
    }

    &_listlabel {
      font-weight: bold;
      margin-bottom: 25px;
    }

    &_list {
      list-style: inside;

      &_item {
        font-weight: normal;
      }
    }

    &_item {
      padding: 40px 0 25px 30px;
      height: auto;
      min-height: 800px;
      display: block;
      margin-bottom: 60px;

      .planCard_list_btns_btn {
        width: 300px;
        cursor: pointer;
        padding: 17px 45px 14px;
        border-radius: 5px;
        font-size: 16px;
        line-height: 1.2;
        text-align: center;
        display: block;
        margin: 0 auto;
        border: none;

        &:disabled {
          opacity: .3;
        }

        &:first-child {
          margin-bottom: 20px;
        }
      }

      &__brown {
        background-color: $ps-bg-brown;

        .planCard_list_btns_btn {
          background-color: $ps-bg-dark-brown;
          color: $ps-font-white;

          &:first-child {
            margin-bottom: 20px;
          }

          &--secondary {
            color: $ps-bg-dark-brown;
            background-color: $ps-bg-white;
          }
        }
      }

      &__green {
        background-color: $ps-bg-green;

        .planCard_list_btns_btn {
          background-color: $ps-bg-dark-green;
          color: $ps-font-white;

          &:first-child {
            margin-bottom: 20px;
          }

          &--secondary {
            color: $ps-bg-dark-green;
            background-color: $ps-bg-white;
          }
        }
      }

      &__yellow {
        background-color: rgba($ps-bg-yellow, .5);

        .planCard_list_btns_btn {
          background-color: $ps-bg-dark-yellow;
          color: $ps-font-white;

          &:first-child {
            margin-bottom: 20px;
          }

          &--secondary {
            color: $ps-bg-dark-yellow;
            background-color: $ps-bg-white;
          }
        }
      }
    }
  }

}

@media only screen and (min-width: $tablet) {
  .planCard_headline {
  }

  .planCard_list {
    &_headline {
      width: 350px;
      position: unset;
      margin-bottom: 20px;
    }

    &_mobil {
      display: none;
    }

    &_desktop {
      display: block;
    }

    &_box {
      flex: 1;
    }

    &_item {
      display: flex;
    }

    &_image {
      height: 480px;
      width: calc(100% - 80px);
      margin-left: 80px;
      margin-top: 20px;

      &_plusicon {
        background-size: 80px;
        height: 80px;
        width: 80px;
        top: -20px;
        left: 40px;
      }
    }

    &_item {
      padding: 40px 0 25px 50px;

      &__yellow, &__brown, &__green {
        .planCard_list_btns_btn {
          width: calc(50% - 10px);
          float: left;

          &:first-child {
            margin-right: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
