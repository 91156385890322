.header {
  width: 100%;
  height: 150px;
  background-color: $ps-bg-blue;

  img {
    margin: 0 auto;
    width: 143px;
    height: auto;
    position: relative;
    top: 47px;
    display: block;
  }
}
