.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  margin-bottom: 58px;

  &--striped {
    tbody tr:nth-of-type(#{even}) {
      background-color: $ps-bg-light-gray;
    }
  }

  &_action {
    color: $ps_font-gray;
    font-weight: bold;

    &_icon {
      height: 25px;

      @include media-breakpoint-up(xl) {
        height: 32px;
      }
    }
  }

  &_col-highlighted {
    color: $ps-font-black;
    font-weight: bold;
  }

  &_col-greyedout {
    color: $ps-gc-gray;
  }

  thead {
    th {
      text-transform: uppercase;
      border-bottom: 2px solid $ps-bc-gray;
    }
  }

  th {
    padding: 7px 12px 8px;
  }

  td {
    padding: 12px 20px;
  }

  tbody tr:first-of-type td {
    padding-top: 28px;
  }

  th, td {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    vertical-align: middle;
  }

  &--align-center {
    th, td {
      vertical-align: middle;
      text-align: center;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .table {
    th, td {
      font-size: 20px;
      line-height: 23px;
    }

    th {
      padding: 9px 20px 7px;
    }

    td {
      padding: 9px 20px 8px;
    }

    &_column-sticky-tablet {
      position: static;
    }
  }
}
