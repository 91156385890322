.radio_buttons {
  margin-top: 20px;
  .small {
    width: 35%;
  }

  .full {
    width: 100%;

  }

  label {
    display: flex;
    margin-bottom: 20px;
    float: left;

    input {
      flex: 0 0 auto;
      margin-right: 10px;
    }

    span {
      flex: 1 1 auto;
    }
  }
}