.choiceList {
  margin: 0 15px 60px;

  .payment_methods{
    background-color: $ps-bg-white;
    padding: 10px 20px;
  }
  &_wrapper {
    background-color: $ps-bg-white;
    padding: 10px 20px;
    &--additionally{
      margin-top: 20px;
      padding: 10px 0;
      .choiceList{
        &_content{
          padding: 10px 20px;
        }
        &_label{
          // max-width: 100px;
        }
        &_headline{
          font-weight: bold;
          padding-left: 20px;
        }
        &_remove-btn{
          color: $ps-font-gray;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }

  &_headline {
    padding-bottom: 20px;
    margin: 0;
    border-bottom: 1px solid $ps-bc-black;
  }

  &_subheadline {
    margin: 0;
  }

  &_label {
    font-weight: bold;
    margin: 5px 0 10px;
  }

  &_price {
    width: 220px;
    color: $ps-font-gray;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &_disclaimer {
    width: 220px;
    color: $ps-font-gray;
    font-size: 11px;
    line-height: 15px;
  }

  &_edit {
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    background-color: #2C3437;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    color: white;
  }

  &_plan {
    margin: 30px 0;
    padding: 20px;
    color: $ps-font-white;
    text-align: center;
    img{
      height: 40px;
      width: auto;
    }

    p {
      margin: 0;
    }

    &--brown {
      background-color: $ps-bg-brown;
    }

    &--green {
      background-color: $ps-bg-green;
    }

    &--yellow {
      background-color: $ps-bg-yellow;
    }
  }
}


@media only screen and (min-width: $tablet) {
  .choiceList {
    margin: 0;

    &_label, &_plan img{
      width: 100%;
    }
  }
}
