.servicepointslist{
  color: $ps-font-black;
  padding: 70px 0;
  &_wrapper{
    max-width: $max-width;
    margin: 0 auto;
  }
  &_headline, &_text{
    margin: 0 40px 30px;
    text-align: center;
  }
  &_btn{
    width: 320px;
    margin: 0 auto;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    background-color: #7099AD;
    cursor: pointer;
    display: block;
    color: white;
  }
  &_list{
    display: flex;
    margin: 25px 20px 10px;

    &__item{
      padding: 0 5px;
      width: calc(100% / 3);
      display: inline-block;
      text-align: center;
      .icon{
        height: 50px;
        width: auto;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }
  }
}


@media only screen and (min-width: $tablet) {
  .servicepointslist {
    &_headline, &_text {
      margin: 0 40px 30px;
    }

    &_list{
      margin: 50px 0;
      &__item{
        padding: 0 30px;
        .icon{
          margin-bottom: 30px;
        }
      }
    }
  }
}
