// some colors
// background colors
$ps-bg-white: white;
$ps-bg-black: #2C3437;

$ps-bg-blue: #7099AD;
$ps-bg-light-blue: #B8CCD6;

$ps-bg-gray: #F2F3F4;
$ps-bg-disabled-gray: #CCCCCC;
$ps-bg-disabled-transparent-gray: #CCCCCCAA;
$ps-bg-light-gray: #F2F3F4;
$ps-bg-dark-gray: #2C3437;

$ps-bg-brown: #BEA891;
$ps-bg-light-brown: #EBE4DE;
$ps-bg-dark-brown: #8E6F52;

$ps-bg-green: #96B5AC;
$ps-bg-light-green: #DFE8E6;
$ps-bg-dark-green: #5A8075;

$ps-bg-yellow: #DFBD79;
$ps-bg-light-yellow: #F5EBD7;
$ps-bg-dark-yellow: #C48D42;

// font colors
$ps-font-dark-gray: #515150;
$ps-font-gray: #95999B;
$ps-font-light-gray: #A0A1A3;
$ps-font-black: #2C3437;
$ps-font-white: white;
$ps-font-red: #DF6134;
$ps-font-green: #34df7f; // von [SK] ausgesucht
$ps-font-blue: #0645AD;

$ps-font-tertiary: #6B7073;
$ps-bg-quarternary: #FFB55B;

// border colors
$ps-bc-white: white;
$ps-bc-black: #2C3437;
$ps-bc-blue: #7099AD;
$ps-bc-gray: #95999B;
$ps-bc-red: #DF6134;

// gradient color
$ps-gc-gray: #6c6c6b;
$ps-gc-darkgray: #494948;

// cookie color
$ps-bg-cookie-gray: #D2D2D1;
$ps-btn-cookie-green: #7DAF3B;
$ps-btn-cookie-black: black;

