.choosePlan{
  background-color: $ps-bg-gray;
  color: $ps-font-black;
  text-align: center;
  &_wrapper{
    padding: 50px 0;
  }
  &_boxes{
    overflow: auto;
    width: 100%;
    &_text{
      background-color: $ps-bg-white;
      padding: 20px 0;
    }
    &_left{
      margin-bottom: 20px;
    }
    &_left, &_right{
      img{
        height: auto;
      }
      &_btn{
        display: block;
        background-color: #7099AD;
        border-radius: 5px;
        padding: 10px 30px;
        margin: 0 20px;
        color: white;
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
 .choosePlan_boxes{
   &_left{
     padding-right: 20px;
   }
   &_right{
     padding-left: 20px;
   }
   &_left, &_right{
     float: left;
     width: 50%;
   }
 }
}
