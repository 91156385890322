.payment_methods {
  margin: 0 auto;

  &_wrapper{
    max-width: 250px;
    margin: 0 auto;
    background-color: transparent;
  }

  &_label {
    display: inline;
    margin: 0;
    color: $ps-font-gray;
    font-size: 14px;
  }

  &_img {
    float: right;
    height: 20px;
    width: 38px;
    padding: 0 1px;
    line-height: 10px;
    margin-top: 5px;
  }
}