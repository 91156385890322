.hero {
  height: 500px;
  position: relative;
  background-color: $ps-bg-gray;

  &_wrapper {
    position: relative;

    .plusicon {
      position: absolute;
      z-index: 30;
      top: -24px;
      left: -24px;
    }
  }

  &_image {
    position: absolute;
    height: 250px;
    width: 100%;
    z-index: 20;
    background-image: url("/static/image/home/2230.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &_campaign {
    z-index: 30;
    position: absolute;
    margin: 0 40px;
    top: 500px;
    background-color: $ps-bg-white;
    height: 300px;
    border-radius: 5px;
    color: $ps-font-black;
    padding: 30px;

    img {
      width: 6rem;
      height: 6rem;
    }
  }

  &_textbox {
    z-index: 30;
    position: absolute;
    margin: 0 40px;
    top: 150px;
    background-color: $ps-bg-white;
    height: 300px;
    border-radius: 5px;
    color: $ps-font-black;
    padding: 30px;

    &_text {
      margin-top: 30px;
    }

    &_btn {
      width: 90%;
      margin: 40px auto 0;
    }
  }
}

@media only screen and (min-width: $mobile) {
  .hero {
    height: 560px;

    &_with_campaign {
      height: 820px;
      /*.container {
      max-width: none;
      margin: 0;
      }*/

      hero_textbox {
        //margin: 0 auto;
        top: 500px;
        //width: 100%;
      }
    }

    p {
      margin-top: 1em;
    }

    &_campaign {
      //width: 100%;
      /*margin: 0 auto;
      background-color: gray;*/

      img {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}

@media only screen and (max-width: $mobile){
  .hero {
    &_with_campaign {
      height: 820px;

      hero_campaign {
        padding: 5px 30px;

        img {
          height: 4rem;
        }
      }
    }
  }
}

@media only screen and (min-width: $laptop) {
  .hero {
    height: 560px;

    &_with_campaign {
      height: 560px;
    }
  }
}

@media only screen and (min-width: $tablet) and (max-width: $laptop) {
  .hero {
    height: 560px;

    &_with_campaign {
      height: 560px;
    }

    &_textbox {
      right: 100px !important;
    }

    &_campaign {
      left: 100px !important;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .hero {
    height: 560px;

    &_image {
      height: 560px;
    }

    &_wrapper {
      position: relative;

      .plusicon {
        top: -40px;
        left: -40px;
      }
    }

    &_textbox {
      position: absolute;
      padding: 30px 40px;
      margin: 0;
      top: 100px;
      right: 150px;
      width: 450px;
      height: 350px;

      p {
        margin-top: 0.5rem;
      }
    }

    &_campaign {
      position: absolute;
      padding: 30px 40px;
      margin: 0;
      top: 100px;
      left: 150px;
      width: 450px;
      height: 350px;

      img {
        left: 10px;
        height: 5rem;
      }

      p {
        margin-top: 0.5rem;
      }
    }
  }
}