.terms-conditions{
  &_content{
    background-color: $ps-bg-white;
    color: $ps-font-black;
    padding: 40px 0 25px 30px;
    margin-bottom: 25px;
    // TODO: remove that if text exist!
    min-height: 600px;
    .label{
      color: $ps-font-gray;
    }
    .margin-40{
      margin-left: 40px;
    }
    a {
        color: $ps-font-blue;
        text-decoration: underline;
    }
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 5px;
    }
    th{
        background-color: $ps-bg-disabled-gray;
    }
  }
}