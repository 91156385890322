.feature-icon-list {
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    margin-bottom: 34px;
    justify-content: start;
    flex-wrap: nowrap;
  }


  &_item {
    border: 1px solid $ps-bc-black;
    border-radius: 10px;
    padding: 7px 3px 6px;
    width: 72px;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 5px;

    @include media-breakpoint-up(md) {
      padding: 12px 6px 14px;
      margin-right: 10px;
      margin-bottom: 10px;
      width: 157px;
    }

    &_icon {
      margin-bottom: 2px;
      height: 30px;
      width: 100%;

      @include media-breakpoint-up(md) {
        margin-bottom: 12px;
        height: 44px;
      }
    }

    &_label {
      display: inline-block;
      font-size: 10px;
      line-height: 1.1;
      font-weight: bold;
      word-break: break-word;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 1.2;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
//icon mb 6 h32
//72x72
//  mr 5px
//7px 3px 6px
