.icon {
  background-repeat: no-repeat;
  background-size: cover;
  height: 140px;
  width: 140px;
  margin: 60px auto;
  background-color: $ps-bg-white;

  &_paypending {
    background-image: url("../static/image/icons/time.png");
  }

  &_x-circle {
    background-image: url("../static/image/icons/x-circle.svg");
  }
}