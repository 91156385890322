.checkout {
  &_page {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(xl) {
      flex-wrap: nowrap;
    }

    &_content {
      flex: 1 1 auto;
      order: 1;
      width: 100%;


      @include media-breakpoint-up(xl) {
        order: 1;
        width: auto;
      }
    }
  }

  &_sidebar {
    width: 100%;
    order: 2;

    @include media-breakpoint-up(xl) {
      flex: 0;
      min-width: 290px;
      order: 2;
      margin-left: 20px;
    }

    &_teaser {}
  }
}

.step-form {
  &_nav {
    &_list {
      margin: 0 22px;
      text-align: left;
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      &_item {
        color: $ps-font-gray;
        font-weight: bold;
        padding: 10px;
        margin: 10px 5px;
        background-color: $ps-bg-white;
        display: inline-block;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &--active {
          color: $ps-font-black;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      &_list {
        text-align: center;
        margin: 0;

        &_item {
          width: calc(100% / 5);
          display: inline-block;
          color: $ps-font-gray;
          border-bottom: 2px solid $ps-bc-gray;
          margin: 0 0 40px 0;
          font-weight: bold;
          padding-bottom: 5px;
          background-color: transparent;

          &--active {
            color: $ps-font-black;
            border-color: $ps-bc-black;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .checkout {
    .step-form {
      &_nav {
        &_list {
          display: grid;
          margin-bottom: 1em;

          li, li:last-child {
            margin: 0 auto;
            padding: 5px 0 5px 0;
            width: 100%;
          }

          li:first-child {
            padding-top: 15px;
          }
        }
      }
    }

    .form {
      &-section {
        margin: 0;
      }
    }

    #payment-model {
      .form-check {
        margin: 0 30em 1em 0;

        &-box:last-child {
          margin-bottom: 0;
        }
      }

      .mb-5 {
        margin-bottom: 0 !important;
      }
    }

    .choiceList {
      margin: 0;
    }
    .choiceList_wrapper {
      margin-top: 2rem;
    }

    .payment_methods {
      margin-bottom: 2rem;
    }
  }
}