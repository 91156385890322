.menu {
  z-index: 100;
  height: 70px;
  width: 100%;
  background-color: $ps-bg-blue;
  position: relative;
  color: $ps-font-white;
  border-top: 1px solid $ps-bc-white;
  border-bottom: 1px solid $ps-bc-white;

  &_left {
    width: 70%;
    padding-left: $mobile-left-padding;
  }

  &_left, &_right {
    float: left;
  }

  &_right {
    width: 30%;
    text-align: right;
    height: 100%;

    &_login {
      background-image: url("/static/image/menu/profil.png");
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      display: block;
      margin: 0;
    }

    &_link {
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      &:last-child {
        margin-left: 30px;
      }

      .cart {
        line-height: 31px;
      }
    }
  }

  &_burger {
    margin: 10px 0 5px;
    display: inline-block;
  }

  .mobil {
    .logo {
      margin-left: 10px;
    }

    .container {
      height: 100%;
    }

    .menu_links {
      width: 100%;
      background-color: $ps-bg-blue;
      height: auto;
      overflow: hidden;
      max-height: 0;
      padding: 0 13px;


      transition: max-height 0.15s ease-out;
      border-bottom: 1px solid white;
      border-top: 1px solid white;

      &--open {
        max-height: 500px;
        transition: max-height 0.25s ease-in;

      }

      &_list {
        &_link {
          float: none;
          padding: 0;

          a {
            padding: 30px 0;
            color: $ps-font-white;
            text-align: left;
            display: block;
            margin: 0;
          }
        }
      }
    }
  }

  &_top {
    height: 70px;
    width: 100%;
    padding: 0 13px;
    position: relative;
    background-image: linear-gradient(to bottom, $ps-gc-gray 0%, $ps-gc-darkgray 50%);

    &_language {
      float: right;
      text-align: right;
      padding: 10px 0;

      > img {
        height: 10px;
        width: auto;
      }
      .flag {
        width: 30px;
        margin-left: 10px;
      }
      &_info{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;

        &-name{
          line-height: 1.2;
          font-size: 17px;
          margin: 0;
          max-width: 120px;

          @include media-breakpoint-up(sm) {
            max-width: none;
          }

        }
      }
    }

    &_logout {
      float: right;
      margin-right:30px;
      opacity: 0.8;
      color: white;
      margin-bottom: 0;
      font-size: 20px;

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }

    .logo {
      width: 150px;
      height: 70px;
      background-image: url('/static/image/logo/logo_pana_web.png');
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      margin: 0;
    }
  }

  .desktop {
    display: none;
  }

  &_wrapper {
    height: 100%;

    .logo {
      width: 130px;
      height: 50px;
      background-image: url('/static/image/logo/aquaria_service.svg');
      background-size: 600px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      margin: 10px 0 0 10px;
    }
  }

  &_links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: auto;
    float: right;
    height: 100%;

    &_link {
      float: left;
      height: 100%;

      .cart {
        color: $ps-font-white;
      }

      a {
        display: block;
        color: white;
        text-align: center;
        padding: 21px 16px;
        font-size: 15px;
        text-decoration: none;
        margin: 0;
      }

      .menu_login {
        cursor: pointer;
        background-color: $ps-bg-light-blue;
        color: $ps-font-black;
      }

      &:last-child {
        padding: 21px 0 21px 16px;
      }
    }
  }
}


@media only screen and (min-width: $tablet) {
  .menu {
    position: sticky;
    top: 0;
    padding: 0;

    &_links_link a{
      padding: 21px 37.5px;
      font-size: 17px;
    }

    .mobil {
      display: none;
    }

    .desktop {
      display: block;
    }

    &_top {
      padding: 0;
    }
  }
}
