.registration {

  .text {
    text-align: center;
    font-size: 20px;
  }

  .internet-ready-selection {
    margin-left: 30px;
  }


  .internet-setting-error-text {
    text-align: center;
    color: red;
    font-size: 16px;
  }

  p.info {
    text-align: center;
  }

  #fee {
    margin-left: 15px;
  }

  .form-check {
    margin: 0 0 20px 0;
  }
  .form-check-option-even {
    background-color: $ps-bg-light-gray;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
  .form-check-option-uneven {
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
  .internet-connected-0 {
    display: inline;
  }
  .internet-connected-1 {
    display: inline;
    margin-left: 210px;
  }

  .prepared-order {
    margin: 0 auto 0 auto;
    width: 60%;

    p {
      width: 100%;
      margin-top: 0;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 40px;
      text-align: center;
    }

    .btn-primary {
      width: 100%;
    }

    .success {
      background-color: white;
      text-align: center;
      padding: 10px 0;
      margin-top: 20px;

      .btn-tertiary {
        margin-top: 50px;
      }
    }
  }

  @media only screen and (min-width: $phablet) {
    .selection {
      margin-left: 52px;
    }
  }
}