.cookie {
  display: block;
  padding: .5em 5%;
  background-color: $ps-bg-cookie-gray;
  text-align: center;
  position: fixed;
  bottom: 0;
  z-index: 1000;

  &_content {
    margin-bottom: 0;

    p, .btn, &_link {
      display: block;
    }

    &_link, .btn {
      margin-top: 20px;
    }

    &_link {
      margin-top: 20px;
      color: $ps-font-black;
    }
  }

  &--disable {
    display: none;
  }
}

@media only screen and (min-width: $tablet) {
  .cookie_content {
    .btn, &_link {
      margin-left: 10px;
      margin-top: 0;
    }

    p, .btn, &_link {
      display: inline-block;
    }
  }
}
