// Spacing
//
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
      0: 0,
      1: ($spacer * .5),
      2: ($spacer),
      3: ($spacer * 1.5),
      4: ($spacer * 2),
      5: ($spacer * 3)
  ),
  $spacers
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
) !default;

// break points
$mobile: map-get($grid-breakpoints, 'sm');
$phablet: map-get($grid-breakpoints, 'md');
$tablet: map-get($grid-breakpoints, 'xl');
$laptop: map-get($grid-breakpoints, 'xxl');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;


// widths
$full-width: 100%;
$max-width: 1200px;

$ps-sidenav-width: 240px;


$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.625rem !default;
$input-btn-padding-x:         0.813rem !default;
$input-btn-padding-lg-y:      1rem !default;
$input-btn-padding-lg-x:      1.25rem !default;
$input-btn-line-height:       1.125 !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($ps-bc-blue, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-border-width:      1px !default;


// Forms
$label-margin-bottom:                   .5rem !default;

$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-line-height:                     $input-btn-line-height !default;

$input-bg:                              $ps-bg-white !default;
$input-disabled-bg:                     $ps-bg-light-gray !default;

$input-color:                           $ps-font-black !default;
$input-border-color:                    $ps-font-black !default;
$input-border-width:                    $input-btn-border-width !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              lighten($ps-bc-gray, 25%) !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $ps-font-gray !default;
$input-plaintext-color:                 $ps-font-black !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height:                          calc(#{$input-height-inner} + #{$input-height-border}) !default;
$input-lg-height-inner:                 ($font-size-base * $input-btn-line-height) + ($input-btn-padding-lg-y * 2) !default;
$input-lg-height:                       calc(#{$input-lg-height-inner} + #{$input-height-border}) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-row-gap:                          12px;
$form-row-lg-gap:                       30px;

$form-text-margin-top:                  .25rem !default;

$form-check-input-gutter:               1.25rem !default;
$form-check-input-margin-y:             .3rem !default;
$form-check-input-margin-x:             .25rem !default;

$form-check-inline-margin-x:            30px !default;
$form-check-inline-input-margin-x:      15px !default;

$form-group-margin-bottom:              15px !default;
$form-group-lg-margin-bottom:           36px !default;

$input-group-addon-color:               $ps-font-white !default;
$input-group-addon-bg:                  $ps-bg-black !default;
$input-group-addon-border-color:        $input-border-color !default;

// Form validation
$form-feedback-margin-top:          10px !default;
$form-feedback-font-size:           16px !default;
$form-feedback-valid-color:         $ps-font-green !default;
$form-feedback-invalid-color:       $ps-font-red !default;

// mobile
$mobile-left-padding: 13px;
$mobile-headline-line-height: 2.5rem;