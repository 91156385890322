.fixedwidth{
  width: 386px;
}
.registration {
  width: 100%;
  background-color: $ps-bg-light-gray;

  &_connectioncheck {
    .registration_step_content_input_group_inputs {
      padding: 0 30px;
    }

    .registration_step_content_stepbtns {
      text-align: center;

      &_link {
        display: block;
      }

      &_nextbtn {
        float: unset;
      }

      .dropdown {
        text-align: center;

        &_label {
          display: block;

          &--close {
            display: none;
          }
        }

        &_content {
          padding-top: 20px;
          border-top: 1px solid $ps-bc-black;
        }

        &_closebtn {
          border-top: 1px solid $ps-bc-black;
          padding-top: 20px;
          display: block;
          color: $ps-font-gray;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }

  &_step {
    background-color: $ps-bg-white;
    padding: 30px;
    margin-bottom: 60px;

    &_content {
      //&_stepbtns {
      //  text-align: center;
      //
      //  &_link {
      //    color: $ps-font-black;
      //    border-bottom: 1px solid $ps-bc-black;
      //    display: block;
      //  }
      //
      //  &_nextbtn {
      //    width: 215px;
      //    padding: 10px 20px;
      //    border-radius: 5px;
      //    text-align: center;
      //    background-color: $ps-bg-black;
      //    cursor: pointer;
      //    display: block;
      //    margin: 0 auto;
      //    color: $ps-font-white;
      //
      //    &--medium {
      //      max-width: 400px;
      //      width: auto;
      //    }
      //
      //    &--disabled {
      //      background-color: $ps-bg-disabled-gray;
      //    }
      //  }
      //}

      //&_input_group {
      //  margin-bottom: 30px;
      //  display: flex;
      //  flex-wrap: wrap;
      //  justify-content: space-between;
      //
      //  &--split_label{
      //    display: flex;
      //    flex-wrap: wrap;
      //    justify-content: space-between;
      //    .label{
      //      width: 48%;
      //    }
      //  }
      //
      //  &--disable {
      //    display: none;
      //  }
      //
      //  &_label {
      //    color: $ps-font-gray;
      //    margin-bottom: 0;
      //    font-size: 24px;
      //    font-weight: 700;
      //    line-height: 44px;
      //  }
      //
      //  input.payment_option_list_item_input {
      //    width: unset;
      //  }
      //
      //  &_inputs {
      //    width: 100%;
      //    margin-bottom: 40px;
      //
      //    input {
      //      width: 100%;
      //      padding: 20px;
      //      border: solid 1px $ps-bc-black;
      //    }
      //
      //    input.matching_inputs_input{
      //      width: 48%;
      //    }
      //
      //    input[type=radio] {
      //      width: auto;
      //      padding: 0;
      //      border: none;
      //    }
      //
      //    select {
      //      width: 100%;
      //      border-radius: 0;
      //      padding: 20px;
      //      border: solid 1px black;
      //    }
      //
      //    input::-webkit-input-placeholder {
      //      font-weight: bold;
      //      color: $ps-font-black;
      //    }
      //
      //    input::-ms-input-placeholder {
      //      font-weight: bold;
      //      color: $ps-font-black;
      //    }
      //
      //    input::-moz-placeholder {
      //      font-weight: bold;
      //      color: $ps-font-black;
      //      font-size: 14px;
      //    }
      //
      //    &--small {
      //      width: 48%;
      //      display: inline-block;
      //    }
      //
      //    &--full {
      //      width: 100%;
      //    }
      //  }
      //}

      &_infotext {
        padding: 20px;
        border: 1px solid $ps-bc-black;
        border-radius: 5px;
      }

      &_text {
        text-align: center;
        margin-bottom: 60px;
      }

      &_headline {
        color: $ps-font-gray;
        width: 100%;
        text-transform: none;
        margin-bottom: 40px;

        &--split {
          float: left;

          .login {
            display: inline-block;
          }

          &:first-child {
            width: 70%;
          }

          &:last-child {
            width: 30%;
            float: right;
            padding-top: 13px;
            text-align: right;

            p {
              color: $ps-font-black;
            }

            a {
              color: $ps-font-gray;
            }

            a, p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  color: $ps-font-black;

  &_headline {
    margin-bottom: 60px;
    //color: $ps-font-gray;
  }

  &_confirmation_step_plan {
    img {
      height: 40px;
      width: auto;
    }
  }

  &-heatpump {
    $popup-margin: 17.5%;

    .popup-outer {
      z-index: 30;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: $ps-bg-disabled-transparent-gray;

      .popup-inner {
        position: fixed;
        left: 5%;
        right: 5%;
        top: $popup-margin;
        margin: auto;
        max-width: 1000px;
        max-height: 638px;
        background-color: transparent;

        img {
          width: 100%;
          margin: auto;
          max-height: 690px;
        }
      }
    }
  }
}

//@media only screen and (max-width: $tablet) {}

@media only screen and (max-width: $phablet) {
  .registration {
    //&_step {
    //  background-color: $ps-bg-white;
    //  padding: 30px 130px;
    //  width: 75%;
    //  max-width: 1170px;
    //  margin-bottom: 60px;
    //  margin-right: 5%;
    //  float: left;

      //&_content {
      //  &_input_group_label {
      //    font-size: 24px;
      //  }
      //
      //  &_stepbtns {
      //    text-align: left;
      //
      //    &_link {
      //      display: inline;
      //    }
      //
      //    &_nextbtn {
      //      float: right;
      //    }
      //  }
      //}
    //}

    &-heatpump {
      $popup-margin: 30%;

      .popup-close-btn {
        top: $popup-margin;
      }

      .popup-inner {
        top: $popup-margin;
      }
    }
  }
}
