.datepicker-toggle {
  height: 56px;
  display: block;

  input[type="date"]::-webkit-datetime-edit {
    color: red;
  }

  input {
    width: 90%;
    border-left: none;
  }

  &-button {
    background-color: $ps-bg-black;
    border-left: 1px solid $ps-bg-black;
    border-top: 1px solid $ps-bg-black;
    border-bottom: 1px solid $ps-bg-black;
    width: 66px;
    height: 100%;
    display: block;
    background-image: url("/static/image/icons/checkout/calendar.png");
    float: left;
    background-size: 35px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
