.registration {

  .btn-primary {
    margin-top: 30px;
  }

  .message {
    text-align: center;
    margin: 50px auto 20px auto;
  }
}