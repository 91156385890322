.cart {
  cursor: pointer;
  font-size: 30px;
  background-repeat: no-repeat;

  &_overlay {
    width: calc(100vw - 30px);
    margin: 0 auto;
    height: calc(100vh - 70px);
    visibility: hidden;
    position: absolute;
    top: 50px;
    right: 5px;
    transition: .2s ease;
    background-color: $ps-bg-white;
    padding: 10px;
    opacity: 0;

    &_price {
      margin-top: 10px;
      font-size: 14px;
      color: $ps-font-gray;
      width: 130px;
    }

    &_wrapper {
      margin-bottom: 30px;
    }

    &_selected-plan {
      padding: 20px;
      position: relative;

      &_info {
        text-align: left;
        margin-top: 20px;
      }

      &_image {
        position: absolute;
        width: 190px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &_info img {
        max-width: 200px;
      }

      &--yellow {
        background-color: $ps-bg-yellow;
      }

      &--brown {
        background-color: $ps-bg-brown;
      }

      &--green {
        background-color: $ps-bg-green;
      }
    }

    &_label {
      margin-top: 10px;
      border-bottom: 1px solid $ps-bc-black;
      text-align: left;
      font-size: 12px;
      width: 50%;
      float: left;
    }

    &_close {
      background-image: url("/static/image/icons/x-circle.svg");
      background-size: 20px;
      background-position: right;
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
      cursor: pointer;
      float: right;
    }

    &_label, &_close {
      color: $ps-font-black;
      display: inline-block;
      text-align: left;
    }

    &_left {
      width: 40%;
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }

    &_protection-plan {
      .clearfix {
        margin-bottom: 20px;
      }
      a.btn_line_gray {
        padding: 0;
        margin-top: 20px;
        width: 40px;
        margin-bottom: 20px;
      }

      .btn {
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
  .cart {
    cursor: pointer;
    font-size: 30px;
    background-repeat: no-repeat;

    &_overlay {
      top: 71px;
      right: 0px;
      min-width: 580px;
      width: auto;
      height: auto;

      &_price {
        margin-top: 10px;
        font-size: 14px;
        color: $ps-font-gray;
        width: 130px;
      }

      &_wrapper {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .split {
        width: 45%;
        display: inline-block;

        &:last-child {
          width: 50%;
        }
      }

      &_selected-plan {
        padding: 20px;
        position: relative;

        .clearfix {
          margin-bottom: 20px;
        }

        &_image {
          position: absolute;
          width: 190px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &_info img {
          max-width: 200px;
        }

        &--yellow {
          background-color: $ps-bg-yellow;
        }

        &--brown {
          background-color: $ps-bg-brown;
        }

        &--green {
          background-color: $ps-bg-green;
        }
      }

      &_label {
        margin-top: 10px;
        border-bottom: 1px solid $ps-bc-black;
        text-align: left;
        font-size: 12px;
        width: 50%;
        float: left;
      }

      &_close {
        float: right;
        text-align: right;
        background-image: url("/static/image/icons/x-circle.svg");
        background-size: 20px;
        background-position: right;
        background-repeat: no-repeat;
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      &_label, &_close {
        color: $ps-font-black;
      }

      &--active {
        opacity: 1;
        visibility: visible;
      }

      &_protection-plan {
        a.btn_line_gray {
          padding: 0;
          margin-top: 20px;
          width: 40px;
          margin-bottom: 20px;
        }

        .btn {
          max-width: 300px;
          margin: 0 auto;
        }
      }
    }
  }
}
