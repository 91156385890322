.footer {
  background-color: $ps-bg-dark-gray;
  color: $ps-font-white;
  text-align: center;
  padding: 20px 13px;

  &_line {
    border-top: 1px solid $ps-bg-white;
  }

  &_headline {
    margin: 0;
  }

  &_icons {
    &_icon {
      display: inline-block;
      margin: 10px;
      width: 50px;
      height: 50px;
      text-indent: -9999px;
      background-size: 50px;
      background-position: center;

      a {
        display: block;
        width: 50px;
        height: 50px;
      }

      &--facebook {
        background-image: url('/static/image/icons/social/facebook.png');
      }

      &--insta {
        background-image: url('/static/image/icons/social/insta.png');
      }

      &--linkedin {
        background-image: url('/static/image/icons/social/linkedin.png');
      }

      &--youtube {
        background-image: url('/static/image/icons/social/youtube.png');
      }

      &--twitter {
        background-image: url('/static/image/icons/social/twitter.png');
      }
    }
  }

  &_links {
    max-width: 800px;
    margin: 0 auto;
    &_link {
      width: 25%;
      display: inline-block;

      a {
        color: $ps-font-white;
        font-size: 10px;
      }
    }
  }

  &_copyright {
    font-size: 10px;
  }
}

@media only screen and (min-width: $tablet) {
  .footer {
    padding: 0;

    &_headline, &_icons {
      width: 50%;
      display: block;
      float: left;
    }

    &_icons {
      text-align: left;
    }

    &_headline {
      text-align: right;
      margin-top: 20px;
    }

    &_links {
      padding-top: 10px;

      &_link {

      }
    }

    &_desktop {
      height: 50px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .footer {
    &_links {
      &_link {
        width: 100%;
      }
    }
  }
}