.overview {
  margin-top: 5px;
  height: 300px;
  padding: 50px 0;
  background-color: $ps-bg-blue;

  &_wrapper{
    height: 100%;

    padding: 20px 0;
  }
  &_item {
    width: 50%;
    float: left;
    text-align: center;
    color: $ps-font-white;

    &:nth-child(n+3) {
      margin-top: 20px;
    }

    strong {
      font-size: 30px;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .overview {
    height: 120px;
    padding: 0;
    background-color: transparent;

    &_wrapper{
      background-color: $ps-bg-blue;
      height: 100%;
      padding: 0;
    }

    &_item {
      width: 25%;
      height: 100%;
      padding: 30px 0;

      &:nth-child(n+3) {
        margin-top: 0;
      }
    }
  }
}
