.selectbox select {
  background: transparent;
  padding: 20px;
  -webkit-appearance: none;
}
.selectbox {
  overflow: visible;
  background: url("/static/image/icons/selectbox/arrow.png") no-repeat;
  background-size: 20px;
  background-position: 96% center;
}
.selectbox select::-ms-expand {
  display: none;
}