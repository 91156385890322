.plan-comparison {
  color: $ps-font-black;
  margin-bottom: 58px;

  &_headline {
    text-align: center;
    margin-bottom: 38px;
  }

  &_table {
    table-layout: auto;

    @include media-breakpoint-up(md) {
      table-layout: fixed;
    }

    th {
      padding: 20px;
    }

    td {
      padding: 12px;
    }

    th, td {
      font-size: 17px;
      line-height: 19px;
      border-bottom: 1px solid $ps-bc-black;
    }

    tbody tr:first-of-type td {
      padding-top: 12px;
    }

    thead {
      th {
        padding: 33px 31px;
        min-width: 187px;
      }

      th:nth-child(2) {
        background-color: $ps-bg-brown;
      }

      th:nth-child(3) {
        background-color: $ps-bg-green;
      }

      th:nth-child(4) {
        background-color: $ps-bg-yellow;
      }
    }

    &_header_hint {
      background-color: unset !important;
      padding: unset !important;
      border-bottom: unset !important;

      &_active {
        border-bottom: 2px solid black !important;
        font-size: 15px !important;
      }
    }

    &_feature {
      &--enabled:nth-child(2) {
        background-color: $ps-bg-light-brown;
      }

      &--enabled:nth-child(3) {
        background-color: $ps-bg-light-green;
      }

      &--enabled:nth-child(4) {
        background-color: $ps-bg-light-yellow;
      }

      &_icon {
        width: 30px;
        height: 30px;
      }

      &_name {
        font-weight: bold;
      }

      &--enabled {
        font-weight: bold;
      }

      &_footer {
        color: $ps-font-white;

        &:nth-child(2) {
          background-color: $ps-bg-brown;
        }

        &:nth-child(3) {
          background-color: $ps-bg-green;
        }

        &:nth-child(4) {
          background-color: $ps-bg-yellow;
        }

        &_price {
          display: block;
          font-size: 36px;
          line-height: 39px;
          letter-spacing: 3px;
        }

        &_subtitle {
          font-size: 17px;
        }
      }
    }
  }
}

.disabled-column {
  background-color: lightgrey !important;
}

@media only screen and (min-width: $tablet) {
  .plan-comparison {
    &_headline {
      margin-bottom: 76px;
    }

    &_table {
      th, td {
        font-size: 24px;
        line-height: 28px;
      }

      th {
        padding: 32px 55px;
      }

      td {
        padding: 22px;
      }


      tbody tr:first-of-type td {
        padding-top: 22px;
      }

      &_feature {
        &_icon {
          width: 45px;
          height: 45px;
        }

      }
    }
  }
}
