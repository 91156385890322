.no-match-404 {
  background-color: $ps-bg-gray;
  padding-bottom: 40px;

  &_icon{
    background-image: url("/static/image/icons/x-circle.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 140px;
    width: 140px;
    margin: 60px auto;
  }

  &_subheadline{
    margin: 0;
    font-weight: bold;
  }

  &_content {
    padding: 30px 20px 50px;
    background-color: $ps-bg-white;
    text-align: center;
    color: $ps-font-black;
    .btn{
      max-width: 300px;
      margin: 0 auto;
    }
  }
}


@media only screen and (min-width: $tablet) {
  .no-match-404 {

  }
}